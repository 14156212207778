<template>
	<div class="ui-flex">
		<a-button size="small" @click="onBack">
			<Icon icon="ArrowLeftOutlined"></Icon>
		</a-button>
		<a-divider type="vertical" />
		<div class="ui-header__title">{{ title }}</div>
	</div>
</template>

<script>
	import {Icon} from "@/components/icon/icon.js";
	export default {
		components: {
			Icon
		},
		props: {
			title: {
				type: String,
				default: ''
			}
		},
		methods: {
			onBack() {
				this.$emit('back')
			}
		}
	}
</script>

<style scoped>
	.ui-flex {
		display: flex;
		padding: 0px 0 20px;
		align-items: center;
		border-bottom: solid 1px #e8e8e8;
	}
	.ui-header__title {
		color: rgb(0, 0, 0);
		font-size: 16px;
	}
</style>
