<template>
	<div>
		<span @click="onShowModal">
			<slot>
				<a-button type="primary">选择商品</a-button>
				<span> 已选{{selectedRowKeys.length}}件商品</span>
			</slot>
		</span>
		<a-modal v-model:visible="visible" width="850px" title="选择商品" @cancel="onCancel" @ok="onOk">
			<a-form ref="formRef" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item label="商品名称" name="mainTitle" class="ui-form__item">
						<a-input v-model:value="formState.mainTitle" placeholder="请输入商品名称"/>
					</a-form-item>
					<a-form-item label="商品分类" name="classifyId" class="ui-form__item">
						<div style="width: 190px;">
							<a-cascader
								v-model:value="formState.classifyId"
								:options="classifyList"
								:fieldNames="{
									label: 'title',
									value: 'id',
									children: 'children'
								}"
								placeholder="请选择商品分类"
							/>
						</div>
					</a-form-item>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="onReset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-spin :spinning="loading">
				<div style="margin-top: 20px;">
					<a-table :pagination="pagination" :columns="columns" :dataSource="dataList"
						:rowSelection="{selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll , type: type}" rowKey="id" :scroll="{ x: 1300 }">
						<template #bodyCell="{ column, record, index }">
							<template v-if="column.key === 'image'">
								<a-image style="width: 40px;" :src="record.imgUrl"></a-image>
							</template>
							<template v-if="column.key === 'card'">
								<div v-if="record.isCard">
									<a-tag color="orange">{{ ['次卡', '年卡'][record.cardType - 1] }}</a-tag>
									<div v-if="record.cardType === 1">卡次数：{{ record.cardBalanceTimes }}</div>
									<div>卡等级：{{ record.cardTitle }}</div>
								</div>
								<div v-if="record.isRechargeCard">
									<a-tag color="orange">线上会员卡</a-tag>
									<div v-if="!record.isCardGoods">卡初始金额：{{ record.initAmount }} 元</div>
									<div>卡等级：{{ record.rechargeCardTitle }}</div>
								</div>
								<div v-if="!record.isRechargeCard && !record.isCard">--</div>
							</template>
							<template v-if="column.key === 'coupon'">
								<div v-if="record.isCoupon">
									<div v-if="record.ticketBatchNum">
										<div>兑换券批次号：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatch?.batchNo }}</span></div>
										兑换券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatchNum }}张</span>
									</div>
									<div v-if="record.snackBatchNum">
										<div>卖品券批次号：<span style="color: rgb(102, 102, 102);">{{ record.snackBatch?.batchNo }}</span></div>
										卖品券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.snackBatchNum }}张</span>
									</div>
								</div>
								<span v-else>--</span>
							</template>
							<template v-if="column.key === 'price'">
								<div>销售价：{{ record.nowPrice }}</div>
								<div>成本价：{{ record.costPrice }}</div>
								<div>市场价：{{ record.originPrice }}</div>
							</template>
							<template v-if="column.key === 'isDisabled'">
								<div v-if="id">
									<span v-if="record.isShelf">已上架</span>
									<span v-else>已下架</span>
								</div>
								<div v-else>
									<span v-if="record.isDisabled === 0">已启用</span>
									<span v-else>已禁用</span>
								</div>
							</template>
						</template>
					</a-table>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import { getStoreGoodsList} from '@/service/modules/goods.js';
	import {
		getGoodsAllClassifyList
	} from '@/service/modules/mall.js';
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			selectItem: {
				type: Array,
				default: () => {
				    return []
				}
			},
			type: { // checkbox or radio
				type: String,
				default: 'checkbox'
			},
			id: { // 影院ID
				type: null,
				default: undefined
			},
			isRechargeCard: {  // 是否含有在线会员卡
				type: null,
				default: undefined
			}
		},
		watch: {
			id(newVal) {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.getData();
			},
      value: {
        handler(newVal) {
          if(this.value){
            this.selectedRowKeys = JSON.parse(JSON.stringify(newVal));
          }
        },
        deep: true,
        immediate: true
      },
			selectItem: {
				handler(newVal) {
					this.selectedListData = JSON.parse(JSON.stringify(newVal));
				},
				deep: true,
				immediate: true
			}
		},
		created() {
			if(this.value){
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
			}
		},
		data() {
			return {
				loading: false,
				visible: false,
				selectedRowKeys: [], // 选择的id集合
				selectedListData: [],
				dataList: [],
				columns: [{
					title: '商品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '商品图片',
					key: 'image',
					width: 90
				}, {
					title: '剩余库存',
					dataIndex: 'stock',
					width: 100
				}, {
					title:'价格信息',
					key: 'price',
					width: 120
				}, {
					title: '卡信息',
					key: 'card'
				}, {
					title: '券信息',
					key: 'coupon'
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '商品分类',
					dataIndex: 'classifyName'
				}],
				formState: {},
				searchData: {},
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				classifyList: [],
				
			}
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.searchData.classifyId && this.searchData.classifyId.length) {
					this.searchData.classifyId = this.searchData.classifyId[this.searchData.classifyId.length - 1];
				} else {
					this.searchData.classifyId = undefined;
				}
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getStoreGoodsList({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					saleType: 1,
					status: this.status,
					cinemaId: this.id,
					isRechargeCard: this.isRechargeCard,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.pagination.total = ret.data.totalCount;
					this.dataList = ret.data.list;
				}
			},
			onShowModal() {
				this.getData();
				this.getClassifyList();
				this.visible = true;
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedListData = this.selectedListData.filter(item => {
							return item.id !== record.id;
						});
					}
				} else {
					if (selected) {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onReset() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			onCancel() {
				this.visible = false;
				this.$refs.formRef.resetFields();
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
			},
			onOk() {
				this.$refs.formRef.resetFields();
				this.$emit('update:value', this.selectedRowKeys);
				this.$emit('change', this.selectedListData);
				this.visible = false;
			},
			async getClassifyList() {
				let res = await getGoodsAllClassifyList({
					classifyType: 1
				});
				if (res.code === 200) {
					let classifyList = res.data.filter(item => {
						item.children = res.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
				}
			},
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 20px;
	}
</style>
