<template>
	<div>
		<span @click="onShowModal">
			<slot>
				<a-button type="primary">选择影片</a-button>
				<span> 已选{{selectedRowKeys.length}}部影片</span>
			</slot>
		</span>
		<a-modal v-model:visible="visible" width="850px" title="选择影片" @cancel="onCancel" @ok="onOk">
			<a-form ref="formRef" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" label="影片名称" name="name">
						<a-input placeholder="请输入" v-model:value="formState.name"></a-input>
					</a-form-item>
					<a-col :span="14" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="onReset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>
			<a-spin :spinning="loading">
				<div>
					<a-table :pagination="pagination" :columns="columns" :dataSource="dataList" size="small"
					:rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange, type: 'radio'}" rowKey="id">
						<template #bodyCell="{ column, record, index }">
						</template>
					</a-table>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import { getFilmList } from '@/service/modules/cinema.js';
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			type: { // checkbox or radio
				type: String,
				default: 'checkbox'
			},
			id: { // 影院ID
				type: null,
				default: undefined
			}
		},
		watch: {
			id(newVal) {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.getData();
			}
		},
		created() {
            if(this.value){
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
			}
		},
		data() {
			return {
				loading: false,
				visible: false,
				selectedRowKeys: [], // 选择的id集合
				dataList: [],
				formState: {},
				searchData: {},
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				columns: [{
					title: '影片名称',
					dataIndex: 'name'
				}]
			}
		},
		methods: {
			onSearch() {
				this.pagination.current = 1;
				//this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.getData();
			},
			async getData() {
				const postData ={
					cinemaId: this.id,
					...this.searchData,
				}
				this.loading = true;
				try {
					let ret = await getFilmList(postData);
					this.loading = false;
					if (ret.code === 200) {
						this.dataList = ret.data;
					}
				} catch (error) {
					this.loading = false;
				}
				
			},
			onShowModal() {
				this.getData();
				this.visible = true;
			},
			// 列表批量选中
            onSelectChange: function (selectedRowKeys) {
                //console.log('selectedRowKeys changed: ', selectedRowKeys);
                this.selectedRowKeys = selectedRowKeys;
            },
			onReset() {
				this.$refs.formRef.resetFields();
				this.onSearch();
			},
			onCancel() {
				this.visible = false;
				this.$refs.formRef.resetFields();
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
			},
			onOk() {
				this.$emit('update:value', this.selectedRowKeys);
				this.visible = false;
				this.$refs.formRef.resetFields();
			},
		}
	}
</script>

<style scoped>
	.ui-form-item {
		margin-right: 20px;
	}
</style>
