import { send } from "../index";

export function getBannerList(data, opts = {}) {
	//  广告图/轮播图列表
    return send({
        url: "/admin/platNavigation/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function enableImg(data, opts = {}) {
	//  启用广告图/轮播图
    return send({
        url: "/admin/platNavigation/pullOnShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disableImg(data, opts = {}) {
	//  禁用广告图/轮播图
    return send({
        url: "/admin/platNavigation/pullOffShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteImg(data, opts = {}) {
	//  删除广告图/轮播图
    return send({
        url: "/admin/platNavigation/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getDetail(data, opts = {}) {
	//  广告图/轮播图详情
    return send({
        url: "/admin/platNavigation/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveImg(data, opts = {}) {
	//  新增广告图/轮播图
    return send({
        url: "/admin/platNavigation/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateImg(data, opts = {}) {
	//  修改广告图/轮播图
    return send({
        url: "/admin/platNavigation/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPromotionActivitie(data, opts = {}) {
	//  推广券广告列表
    return send({
        url: "/admin/promotionActivitie/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function savePromotionActivitie(data, opts = {}) {
	//  推广券广告新增
    return send({
        url: "/admin/promotionActivitie/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getPromotionActivitieDetail(data, opts = {}) {
	//  推广券广告详情
    return send({
        url: "/admin/promotionActivitie/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updatePromotionActivitie(data, opts = {}) {
	//  推广券广告新增
    return send({
        url: "/admin/promotionActivitie/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getActiveRecord(data, opts = {}) {
	//  推广券领取记录
    return send({
        url: "/admin/claimRecord/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function activePullOnShelves(data, opts = {}) {
	//  推广券活动启用
    return send({
        url: "/admin/promotionActivitie/pullOnShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function activePullOffShelves(data, opts = {}) {
	//  推广券活动禁用
    return send({
        url: "/admin/promotionActivitie/pullOffShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteActive(data, opts = {}) {
	//  推广券活动删除
    return send({
        url: "/admin/promotionActivitie/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getShareQR(data, opts = {}) {
	//  小程序二维码
    return send({
        url: "/admin/shareQR.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getBillDetail(data, opts = {}) {
	//  通用海报背景图 -- 详情
    return send({
        url: "/admin/billSetting/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateBill(data, opts = {}) {
	//  通用海报背景图 -- 更新
    return send({
        url: "/admin/billSetting/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getActivityList(data, opts = {}) {
	//  活动列表
    return send({
        url: "/admin/activity/searchList.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function getActivityDetail(data, opts = {}) {
	//  活动详情
    return send({
        url: "/admin/activity/detail.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function saveActivity(data, opts = {}) {
	// 新增活动
    return send({
        url: "/admin/activity/save.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function updateActivity(data, opts = {}) {
	// 修改活动
    return send({
        url: "/admin/activity/update.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function disabledActivity(data, opts = {}) {
	// 活动启用禁用
    return send({
        url: "/admin/activity/upDownShelves.do",
        method: "POST",
        data,
        ...opts,
    });
}

export function deleteActivity(data, opts = {}) {
	// 活动删除
    return send({
        url: "/admin/activity/delete.do",
        method: "POST",
        data,
        ...opts,
    });
}